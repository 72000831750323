<template>
  <div>
    <b-form

      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >

      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        ref="row"
      >

        <!-- Item Name -->
        <b-col>
          <b-form-group
            label="Founder"
            :label-for="'founder-name-'+item.id"
          >
            <b-form-input
              :id="'founder-name-'+item.id"
              v-model="item.name"
              type="text"
              placeholder="Founder's Name"
              required
            />
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col
          v-if="items.length > 1"
          md="auto"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Remove Founder</span>
          </b-button>
        </b-col>
        <b-col md="12">
          <b-row
            v-for="(doc, index) in item.idDocs"
            :key="index"
          >
            <b-col md="3">
              <b-form-group
                label="ID Type"
                :label-for="`Doctype-${index + 1}`"
              >
                <v-select
                  :id="`Doctype-${index + 1}`"
                  v-model="doc.type"
                  taggable
                  push-tags
                  :options="['Aadhaar', 'Voter ID']"
                  label="Document Type"
                  placeholder="Select/Enter ID type"
                  append-to-body
                />
              </b-form-group>
            </b-col>
            <b-col><b-form-group
              label="Upload Document"
              :label-for="`doc-${index + 1}`"
            >
              <b-form-file
                :id="`doc-${index + 1}`"
                v-model="doc.file"
                accept="image/jpeg, image/png, image/gif"
              />
            </b-form-group></b-col>
            <b-col sm="auto">
              <b-button
                v-if="item.idDocs.length > 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-2"
                variant="outline-danger"
                @click="item.idDocs.splice(index, 1)"
              >
                <feather-icon
                  class="mr-25"
                  icon="XIcon"
                />
              </b-button>
              <b-button
                v-if="index === item.idDocs.length - 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-2 mt-2"
                variant="outline-primary"
                @click="item.idDocs.push({
                  type: '',
                  file: '',
                })"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />
                <span>Add Doc</span>
              </b-button>

            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="DIN"
            :label-for="'founder-din-'+item.id"
          >
            <b-form-input
              :id="'founder-din-'+item.id"
              v-model="item.din"
              type="text"
              placeholder="Founder's DIN"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Address"
            :label-for="'founder-address-'+item.id"
          >
            <b-form-input
              :id="'founder-address-'+item.id"
              v-model="item.address"
              type="text"
              placeholder="Founder's Address"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Email"
            :label-for="'founder-email-'+item.id"
          >
            <b-form-input
              :id="'founder-email-'+item.id"
              v-model="item.email"
              type="email"
              placeholder="Founder's Email"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Phone"
            :label-for="'founder-phone-'+item.id"
          >
            <b-form-input
              :id="'founder-phone-'+item.id"
              v-model="item.phone"
              type="tel"
              placeholder="Founder's Phone"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Designation"
            :label-for="'founder-designation-'+item.id"
          >
            <b-form-input
              :id="'founder-designation-'+item.id"
              v-model="item.designation"
              type="text"
              placeholder="Designation in Startup"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Equity %"
            :label-for="'founder-equity-'+item.id"
          >
            <b-form-input
              :id="'founder-equity-'+item.id"
              v-model="item.equity"
              type="number"
              placeholder="Equity % holding in startup"
              required
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <hr></b-col>
      </b-row>

    </b-form>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Founder</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        name: '',
        din: '',
        address: '',
        email: '',
        phone: '',
        designation: '',
        equity: '',
        prevHeight: 0,
        idDocs: [
          {
            type: '',
            file: '',
          },
        ],
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.faqForm.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
