<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Registered Name"
                    label-for="title"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Registered Name"
                      rules=""
                    >
                      <b-form-input
                        id="title"
                        v-model="title"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Startup Registered Name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Brand Name"
                    label-for="brand"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Brand Name"
                      rules=""
                    >
                      <b-form-input
                        id="brand"
                        v-model="brand"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Startup Brand Name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="CIN"
                    label-for="cin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CIN"
                      rules=""
                    >
                      <b-form-input
                        id="cin"
                        v-model="cin"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Startup Registration Number"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="DIPP"
                    label-for="dipp"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="DIPP"
                      rules=""
                    >
                      <b-form-input
                        id="dipp"
                        v-model="dipp"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Startup DIPP Number"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Website"
                    label-for="url"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Website"
                      rules=""
                    >
                      <b-form-input
                        id="url"
                        v-model="url"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Startup Website URL"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Founders' Details tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Founders' Details"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Founders' Details
                  </h5>
                </b-col>
                <b-col md="12">
                  <founders-info />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Business Information tab  -->
          <tab-content
            :before-change="validationFormAddress"
            title="Business Information"
          >
            <validation-observer
              ref="addressRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Business Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Startup Stage"
                    label-for="startupStage"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Startup Stage"
                      rules=""
                    >
                      <v-select
                        id="startupStage"
                        v-model="startupStage"
                        :options="['Ideation',
                                   'Business modelling/Prototyping',
                                   'Minimum Viable Product(MVP)',
                                   'Market traction',
                                   'Product Introduction',
                                   'Revenue generation',
                                   'Scaling']"
                        label="Startup Stage"
                        placeholder="Select/Enter Startup Stage"
                        push-tags
                        taggable
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Startup Industry"
                    label-for="industry"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Startup Industry"
                      rules=""
                    >
                      <v-select
                        id="industry"
                        v-model="industry"
                        :options="[]"
                        label="Startup Industry"
                        placeholder="Select/Enter Startup Industry"
                        push-tags
                        taggable
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Products or Services"
                    label-for="products"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Products or Services"
                      rules=""
                    >
                      <v-select
                        id="products"
                        v-model="products"
                        :options="['Ideation',
                                   'Business modelling/Prototyping',
                                   'Minimum Viable Product(MVP)',
                                   'Market traction',
                                   'Product Introduction',
                                   'Revenue generation',
                                   'Scaling']"
                        label="Products/Services"
                        placeholder="Select/Enter Products or Services"
                        multiple
                        push-tags
                        taggable
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Upload Business Plan"
                    label-for="images"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Plan"
                      rules=""
                    >
                      <b-form-file
                        id="bPlan"
                        v-model="bPlan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Upload Reveunue Projection"
                    label-for="revenueDocs"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Reveunue Projection"
                      rules=""
                    >
                      <b-form-file
                        id="revenueDocs"
                        v-model="revenueDocs"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Upload Incorporation documents"
                    label-for="incDocs"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Incorporation documents"
                      rules=""
                    >
                      <b-form-file
                        id="incDocs"
                        v-model="incDocs"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="auto"><h6 class="mt-25">
                      Additional Documents
                    </h6></b-col>
                    <b-col>
                      <hr></b-col>
                  </b-row>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(doc, index) in otherDocs"
                    :key="index"
                  >
                    <b-col md="3">
                      <b-form-group
                        label="Document Title"
                        :label-for="`Doctype-${index + 1}`"
                      >
                        <b-form-input
                          :id="`Doctype-${index + 1}`"
                          v-model="doc.title"
                          type="text"
                          placeholder="Document Title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col><b-form-group
                      label="Upload Document"
                      :label-for="`doc-${index + 1}`"
                    >
                      <b-form-file
                        :id="`doc-${index + 1}`"
                        v-model="doc.file"
                        accept="image/jpeg, image/png, image/gif"
                      />
                    </b-form-group></b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="otherDocs.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-danger"
                        @click="otherDocs.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="index === otherDocs.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-2 mt-2"
                        variant="outline-primary"
                        @click="otherDocs.push({
                          title: '',
                          file: '',
                        })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Doc</span>
                      </b-button>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormFile, BFormGroup, BFormInput, BRow, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import FoundersInfo from './components/FoundersInfo.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormFile,
    FoundersInfo,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      title: '',
      brand: '',
      cin: '',
      dipp: '',
      url: '',
      totalUnit: '',
      unitPrice: '',
      startupStage: '',
      industry: '',
      products: '',
      bPlan: '',
      revenueDocs: '',
      incDocs: '',
      otherDocs: [
        {
          title: '',
          file: '',
        },
      ],
      required,
      email,
    }
  },
  methods: {
    formSubmitted() {
      this.addStartup()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    addStartup() {
      // this.setVariables()
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $brand_name: String!,
            $cin: String!,
            $dipp_number: String!,
            $industry: String!,
            $lifecycle_stage: String!,
            $title: String!,
            $website_url: String!,
        ) {
          insert_startups_basicinfo(objects: {
            brand_name: $brand_name,
            cin: $cin,
            dipp_number:$dipp_number,
            industry: $industry,
            lifecycle_stage: $lifecycle_stage,
            title: $title,
            website_url: $website_url,
          }) {
            returning {
              id
            }
          }
        }`,
        variables: {
          brand_name: this.brand,
          cin: this.cin,
          dipp_number: this.dipp,
          industry: this.industry,
          lifecycle_stage: this.startupStage,
          title: this.title,
          website_url: this.url,
        },
      })
      // this.resetValues()
      return this.error
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
